var sliders = [];

$(document).ready(function ($) {

	// Sudo slider
	if ($.fn.sudoSlider) {

		// Teaser
		$('.teaser-slider').each(function () {
			var panels = $(this).find('.panel'),
				panelNum = panels.length;

			if (panelNum > 1) {
				teaserSlider = $(this).find('.slider').sudoSlider({
					vertical: false,
					effect: "slide",
					auto: false,
					autowidth: false,
					continuous: true,
					prevNext: true,
					numeric: false,
					slideCount: 1,
					speed: 250,
					responsive: true,
					controlsAttr: 'class="slider-nav"',
					prevHtml: '<a href="#" class="prev"></a>',
					nextHtml: '<a href="#" class="next"></a>',
					initCallback:function () {
						var prev = $('.teaser-slider').find('.slider-nav .prev');
						prev.after('<span class="teaser-slider-counter"><span class="teaser-slider-current">1</span>/<span class="teaser-slider-total">5</span></span>');

						var total = $('.teaser-slider-total');
						if(total.length) {
							total.text(panelNum);
						}
					},
					afterAnimation:function (t, slider) {
						var current = $('.teaser-slider-current');
						if(current.length) {
							current.text(t);
						}
					}
				});
			}
		});

		// Projects
		$('.projects-slider').each(function(index) {
			var panels = $(this).find('.panel'),
				panelNum = panels.length,
				sliderId = 'projects-slider-'+index;

			if (panelNum > 6) {
				sliders[sliderId] = [];
				sliders[sliderId]['slideNum'] = 6;
				sliders[sliderId]['slideNumSm'] = 4;
				sliders[sliderId]['slideNumXs'] = 2;
				sliders[sliderId]['slideNumXxs'] = 1;

				sliders[sliderId]['slider'] = $(this).find('.slider').sudoSlider({
					vertical: false,
					auto: false,
					autowidth: false,
					continuous: true,
					prevNext: true,
					numeric: false,
					slideCount: sliders[sliderId][getSlideNumKey()],
					speed: 250,
					responsive: true,
					controlsAttr: 'class="slider-nav"',
					prevHtml: '<a href="#" class="prev"></a>',
					nextHtml: '<a href="#" class="next"></a>',
				});
			}
		});

		// Publications
		$('.publications-slider').each(function (index) {
			var panels = $(this).find('.panel'),
				panelNum = panels.length,
				sliderId = 'publications-slider-'+index;

			if (panelNum > 4) {
				sliders[sliderId] = [];
				sliders[sliderId]['slideNum'] = 4;
				sliders[sliderId]['slideNumSm'] = 3;
				sliders[sliderId]['slideNumXs'] = 2;
				sliders[sliderId]['slideNumXxs'] = 1;

				sliders[sliderId]['slider'] = $(this).find('.slider').sudoSlider({
					vertical: false,
					auto: false,
					autowidth: false,
					continuous: true,
					prevNext: true,
					numeric: false,
					slideCount: sliders[sliderId][getSlideNumKey()],
					speed: 250,
					responsive: true,
					controlsAttr: 'class="slider-nav"',
					prevHtml: '<a href="#" class="prev"></a>',
					nextHtml: '<a href="#" class="next"></a>',
				});
			}
		});

		// Banner
		$('.banner-slider').each(function () {
			var panels = $(this).find('.panel'),
				panelNum = panels.length;

			if (panelNum > 1) {
				bannerSlider = $(this).find('.slider').sudoSlider({
					vertical: false,
					auto: false,
					autowidth: false,
					continuous: true,
					prevNext: true,
					numeric: false,
					slideCount: 1,
					speed: 250,
					responsive: true,
					controlsAttr: 'class="slider-nav"',
					prevHtml: '<a href="#" class="prev"></a>',
					nextHtml: '<a href="#" class="next"></a>',
				});
			}
		});

		// Gallery
		$('.gallery-slider').each(function(index) {
			var panels = $(this).find('.panel'),
				panelNum = panels.length,
				sliderId = 'gallery-slider-'+index;

			if (panelNum > 5) {
				sliders[sliderId] = [];
				sliders[sliderId]['slideNum'] = 5;
				sliders[sliderId]['slideNumSm'] = 3;
				sliders[sliderId]['slideNumXs'] = 2;
				sliders[sliderId]['slideNumXxs'] = 1;

				sliders[sliderId]['slider'] = $(this).find('.slider').sudoSlider({
					vertical: false,
					auto: false,
					autowidth: false,
					continuous: true,
					prevNext: true,
					numeric: false,
					slideCount: sliders[sliderId][getSlideNumKey()],
					moveCount: 1,
					speed: 250,
					responsive: true,
					controlsAttr: 'class="slider-nav"',
					prevHtml: '<a href="#" class="prev"></a>',
					nextHtml: '<a href="#" class="next"></a>',
				});
			}
		});

		/* Generic item slider */
		$('.item-slider').each(function (index) {
			var id = $(this).attr('id');
			sliders[id] = [];
			var el = $(this);

			el.attr('data-slides') ? sliders[id]['slideNum'] = el.attr('data-slides') : sliders[id]['slideNum'] = 1;
			el.attr('data-slides-sm') ? sliders[id]['slideNumSm'] = el.attr('data-slides-sm') : sliders[id]['slideNumSm'] = 1;
			el.attr('data-slides-xs') ? sliders[id]['slideNumXs'] = el.attr('data-slides-xs') : sliders[id]['slideNumXs'] = 1;
			el.attr('data-slides-xxs') ? sliders[id]['slideNumXs'] = el.attr('data-slides-xxs') : sliders[id]['slideNumXxs'] = 1;

			var panelNum = el.find('.panel').length;

			if (panelNum > sliders[id]['slideNum']) {
				sliders[id]['slider'] = el.find('.slider').sudoSlider({
					//effect: 'fade',
					vertical: false,
					auto: false,
					autowidth: false,
					continuous: true,
					prevNext: true,
					numeric: true,
					slideCount: 3,
					speed: 250,
					responsive: true,
					customLink: 'a.customLink',
					controlsAttr: 'class="slider-nav"',
					prevHtml: '<a href="#" class="prev"></a>',
					nextHtml: '<a href="#" class="next"></a>'
				});
			}
		});

		$(window).on("resizeEnd focus", function () {
			for (var i in sliders) {
				if (!sliders.hasOwnProperty(i))
					continue;

				var orgSlideCount = sliders[i]['slider'].getOption("slideCount");
				var slideCount;

				slideCount = sliders[i][getSlideNumKey()];

				if (slideCount != orgSlideCount) {
					sliders[i]['slider'].setOption("slideCount", slideCount);
				}
			}
			;
		}).resize();

		function getSlideNumKey() {
			var width = $(window).width();

			if (width >= 1200) {
				return 'slideNum';
			} else if (width > 768) { 
				return 'slideNumSm';
			} else if (width > 480) {
				return 'slideNumXs';
			} else {
				return 'slideNumXxs';
			}
		}
	}

});
