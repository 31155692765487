$(document).ready(function ($) {

	$('.eq').matchHeight();

	$('.filter-trig').each(function() {
		var trigger = $(this),
			wrapper = trigger.closest('.filter-wrap'),
			filter = wrapper.find('.filter');
		trigger.on('click', function() {
			wrapper.toggleClass('filter-open');
		});
	});

	$('.item-clickable').on('click', function(e) {
		var itemLink = $(this).find('.item-link');
		if(itemLink.length > 0 && !$(e.target).is('a')) {
			itemLink[0].click();
		}
	});


	/**********************************************
	 RESIZE END / SCROLL END
	 **********************************************/
	var resizeEnd;
	$(window).on('resize', function () {
		clearTimeout(resizeEnd);
		resizeEnd = setTimeout(function () {
			$(window).trigger('resizeEnd');
		}, 100);
	});

	var scrollEnd;
	$(window).on('scroll', function () {
		clearTimeout(scrollEnd);
		scrollEnd = setTimeout(function () {
			$(window).trigger('scrollEnd');
		}, 100);
	});

});

// Helper – existuje element?
(function ($) {
	$.fn.exists = function () {
		return this.length !== 0;
	}
})(jQuery);

function jQuerySelectorEscape(expression) {
	return expression.replace(/[!"#$%&'()*+,.\/:;<=>?@\[\\\]^`{|}~]/g, '\\$&');
}

// Zobrazení elementu ve viewportu
function inViewport(el) {
	var elH = $(el).height(),
			elTop = $(el).offset().top,
			scrollTop = $(window).scrollTop(),
			winH = $(window).height();
	fixedHeaderH = 0; // výška fixní hlavičky

	return ((elTop + elH) > (scrollTop + fixedHeaderH) && (scrollTop - elH) > (elTop - winH));
}
